import {Injectable} from '@angular/core';

const HAS_LOGGED_IN_FLAG = 'has_logged_in'

@Injectable({
  providedIn: 'root'
})
export class LoginMemoryService {

  constructor() {
  }

  rememberLoginSuccess() {
    localStorage.setItem(HAS_LOGGED_IN_FLAG, 'true');
  }

  hasLoggedInBefore(): boolean {
    return localStorage.getItem(HAS_LOGGED_IN_FLAG) === 'true';
  }
}
