import { Component, computed, input } from '@angular/core';
import { Theme } from '../text-tile/theme';
import { NgClass } from '@angular/common';
import { TextTileComponent } from '../text-tile/text-tile.component';
import { AccessLevels } from '../../api/resource/resource-access.service';
import { MayAccess } from '../../api/resource/domain/may-access';
import { Accessibility } from '../../api/product/domain/accessibility';
import { ResourceUtil } from '../../utils/resource-util';

@Component({
  selector: 'app-access-level-indicator',
  standalone: true,
  imports: [
    NgClass,
    TextTileComponent
  ],
  templateUrl: './access-level-indicator.component.html',
  styleUrl: './access-level-indicator.component.scss'
})
export class AccessLevelIndicatorComponent {
  accessRights = input.required<MayAccess>();
  theme = input<Theme>(Theme.light);

  showIndicator = computed<boolean>(() => this.accessRights()?.accessibility !== Accessibility.INCLUDED);
  showPurchaseIcon = computed<boolean>(() => this.accessRights() && ResourceUtil.hasToBeBought(this.accessRights().accessLevels));
  accessLevelStr = computed<string>(() => {
    const accessLevel = this.accessRights()?.accessLevels;

    if (accessLevel === AccessLevels.free) {
      return 'Free';
    }
    if (accessLevel === AccessLevels.freePlus) {
      return 'Free +';
    }
    if (accessLevel === AccessLevels.basic) {
      return 'Basic';
    }
    if (accessLevel === AccessLevels.standalone) {
      return 'Standalone';
    }
    if (accessLevel === AccessLevels.basicPlus) {
      return 'Basic +';
    }
    if (accessLevel === AccessLevels.premium) {
      return 'Premium';
    }
    if (accessLevel === AccessLevels.premiumPlus) {
      return 'Premium +';
    }
    return '';
  });
}
