import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {EditorComponent} from '../../editor-component';
import {ModalExchange} from '../../../elements/generic-modal/modal-exchange';
import {KeyValuePipe, NgIf} from '@angular/common';
import {Period} from '../../../api/product/domain/period';
import {Resource} from '../../domain/resource';
import {Product} from '../../../api/product/domain/product';
import {Bundle} from '../../../api/product/domain/bundle';
import {PostViewComponent} from '../../post/view/post-view.component';
import {Params, Router} from '@angular/router';
import {ResourceWithAccess} from '../../feed/domain/resource-with-access';
import {CommunityRoutes} from '../../community.routes';
import {ProfileService} from '../../profile/profile.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {combineLatest, delay, of} from 'rxjs';
import {MayAccess} from '../../../api/resource/domain/may-access';
import {Accessibility} from '../../../api/product/domain/accessibility';
import {Store} from '@ngrx/store';
import {selectUserCommunitySubscriptionPlan} from '../../../store/community/community.selectors';
import {ProductService} from '../product.service';
import {ResourceUtil} from '../../../utils/resource-util';
import {SubscriptionPlan} from '../../../api/membership/domain/subscription-plan';
import {
  CommunitySubscriptionComponent
} from '../../community-subscription/community-subscription/community-subscription.component';

@Component({
  selector: 'app-get-access-to-resource-modal',
  standalone: true,
  imports: [
    KeyValuePipe,
    NgIf,
    PostViewComponent,
    CommunitySubscriptionComponent
  ],
  templateUrl: './get-access-to-resource-modal.component.html',
  styleUrl: './get-access-to-resource-modal.component.scss'
})
export class GetAccessToResourceModalComponent implements EditorComponent<ResourceWithAccess<unknown>>, OnInit {
  private readonly router = inject(Router);
  private readonly profileService = inject(ProfileService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  private readonly productService = inject(ProductService);

  protected readonly Period = Period;

  data: ModalExchange<ResourceWithAccess<unknown>, ResourceWithAccess<unknown>> | undefined;
  products: Resource<Product<Bundle>>[] = [];
  resource!: Resource<unknown>;
  accessRights!: MayAccess;
  requiredPlan!: SubscriptionPlan;

  showMembershipSection = true;
  showProductsSection = true;
  showLoading = false;

  ngOnInit() {
    if (this.data?.input) {
      this.resource = this.data.input.resource;
      this.accessRights = this.data.input.access;
      this.requiredPlan = Object.entries(this.accessRights.accessLevel)
        .find(([key, value]) => value === Accessibility.AVAILABLE || value === Accessibility.INCLUDED)![0] as SubscriptionPlan
      this.showMembershipSection = this.accessRights.accessibility === Accessibility.NOT_AVAILABLE
        && this.requiredPlan !== SubscriptionPlan.NO_MEMBERSHIP;
      this.showProductsSection = this.accessRights.accessibility !== Accessibility.INCLUDED;
      this.loadProducts();
    }
  }

  onChangeMembershipClick() {
    combineLatest([
      this.profileService.activeCommunity(),
      this.store.select(selectUserCommunitySubscriptionPlan)
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([community, userPlan]) => {
        const queryParams: Params = {
          requiredPlan: this.requiredPlan
        }
        this.router.navigate([CommunityRoutes.membership(community.creationTimestamp, community.uuid)], {queryParams})
          .then(() => {
            this.data?.callback(this.data?.input!);
          })
      });
  }

  onProductClick(product: Resource<Product<Bundle>>) {
    this.profileService.activeCommunity()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(community => {
        void this.router.navigate([
          CommunityRoutes.product(community.creationTimestamp, community.uuid, product.creationTimestamp, product.uuid)
        ]);
        this.data?.callback({resource: this.resource, access: this.accessRights});
      })
  }

  onSubscribedToFeeSubscription() {
    this.showLoading = true;
    of(void 0).pipe(delay(5000))
      .subscribe(() => {
        this.showLoading = false;
        this.data?.callback({resource: this.resource, access: this.accessRights});
        window.location.reload();
      })
  }

  private loadProducts() {
    if (this.showProductsSection) {
      this.productService.getProductsContainingResource(ResourceUtil.getResourceId(this.resource))
        .pipe(
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(products => this.products = products || []);
    }
  }

  protected readonly SubscriptionPlan = SubscriptionPlan;
}
