import {Component, Input, OnInit} from '@angular/core';
import {AccessLevels} from "../../api/resource/resource-access.service";
import {EditorComponent} from "../editor-component";
import {ModalExchange} from "../../elements/generic-modal/modal-exchange";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PostEditorComponent} from "../post/editor/post-editor.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-access-control',
  standalone: true,
  imports: [
    FormsModule,
    PostEditorComponent,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './access-control.component.html',
  styleUrl: './access-control.component.scss'
})
export class AccessControlComponent implements EditorComponent<AccessLevels>, OnInit {

  @Input() data!: ModalExchange<AccessLevels, AccessLevels>;

  constructor() {
  }

  accessLevel!: AccessLevels;

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.accessLevel = this.data.input;
    } else {
      this.accessLevel = AccessLevels.basicPlus;
    }
  }

  getDisplayText(level: string): string {
    const accessLevel = level as AccessLevels;
    if (accessLevel === AccessLevels.unrestricted) {
      return 'Unrestricted';
    }
    if (accessLevel === AccessLevels.free) {
      return 'Free - included in Free, Basic & Premium plan';
    }
    if (accessLevel === AccessLevels.freePlus) {
      return 'Free Plus - buyable with Free plan; included in Basic & Premium plan';
    }
    if (accessLevel === AccessLevels.standalone) {
      return 'Standalone - buyable for everyone, included in Premium';
    }
    if (accessLevel === AccessLevels.basic) {
      return 'Basic - included in membership Basic & Premium';
    }
    if (accessLevel === AccessLevels.basicPlus) {
      return 'Basic Plus - included in membership Premium; buyable with membership Basic';
    }
    if (accessLevel === AccessLevels.premium) {
      return 'Premium - included in membership Premium';
    }
    // if (accessLevel === AccessLevels.premiumPlus) {
    //   return 'Premium Plus - can be bought with membership Premium';
    // }
    return '';
  }

  submit(){
    this.data.callback(this.accessLevel);
  }

  protected readonly Object = Object;
  protected readonly AccessLevels = AccessLevels;
}
